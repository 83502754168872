import React, { useEffect } from 'react';
import { css } from '@emotion/css';
import { LinkButton, useStyles2 } from '@grafana/ui';
import { urlUtil } from '@grafana/data';
import { featureEnabled, reportExperimentView } from '@grafana/runtime';
import { getVariablesUrlParams } from 'app/features/variables/getAllVariableValuesForUrl';
import { getTimeSrv } from 'app/features/dashboard/services/TimeSrv';
import { ShareModalTabProps } from 'app/features/dashboard/components/ShareModal';
import { UpgradeBox, UpgradeContentVertical } from 'app/core/components/Upgrade/UpgradeBox';
import { buildExperimentID, ExperimentGroup } from '../utils/featureHighlights';

export const CreateReportTab = ({ dashboard, onDismiss }: ShareModalTabProps) => {
  const styles = useStyles2(getStyles);
  const getUrl = () => {
    const variablesQuery = urlUtil.toUrlParams(getVariablesUrlParams());
    const timeRangeUrl = urlUtil.toUrlParams(getTimeSrv().timeRangeForUrl());

    return `?${variablesQuery}&${timeRangeUrl}&db-uid=${dashboard.uid}&db-id=${dashboard.id}&db-name=${dashboard.title}`;
  };

  const isReportsCreationDisabled = !featureEnabled('reports.creation');

  useEffect(() => {
    if (isReportsCreationDisabled) {
      reportExperimentView(buildExperimentID('reporting-tab'), ExperimentGroup.Test, '');
    }
  }, [isReportsCreationDisabled]);

  if (isReportsCreationDisabled) {
    return (
      <div className={styles.container}>
        <UpgradeBox featureName={'reporting'} />
        <UpgradeContentVertical
          image={'reporting-email.png'}
          featureName={'reporting'}
          featureUrl={'https://grafana.com/docs/grafana/latest/enterprise/reporting'}
          description={
            'Reporting allows you to automatically generate PDFs from any of your dashboards and have Grafana email them to interested parties on a schedule.'
          }
        />
      </div>
    );
  }

  return (
    <LinkButton href={`reports/new/${getUrl()}`} onClick={onDismiss}>
      Create report using this dashboard
    </LinkButton>
  );
};

const getStyles = () => {
  return {
    container: css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    `,
  };
};
